import classnames from "classnames";
import React, { useState } from "react";
import { SanityImage } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import { useIsRtl } from "../../utils/LayoutContext";
import CarouselButton from "../CarouselButton/CarouselButton";
import styles from "./ImageCarousel.module.scss";
import PlainButton from "../Button/PlainButton/PlainButton";
import SwiperCarousel from "../SwiperCarousel/SwiperCarousel";
import Swiper from "swiper";

interface ImageCarouselProps {
  className?: string;
  images: SanityImage[];
}

const ImageCarousel = ({ images, className }: ImageCarouselProps) => {
  const [swiper, setSwiper] = useState<Swiper>();
  const isRtl = useIsRtl();
  const slides = images.map(image => (
    <div className={styles.slide}>
      <img
        src={
          imageUrlBuilder
            .image(image)
            .width(800)
            .height(600)
            .url()!
        }
        alt={image.alt ?? ""}
        width={800}
        height={600}
      />
    </div>
  ));
  const thumbnails = images.map((image, index) => (
    <PlainButton onClick={() => swiper?.slideTo(index)}>
      <img
        src={
          imageUrlBuilder
            .image(image)
            .width(72)
            .height(54)
            .url()!
        }
        alt={image.alt ?? ""}
        width={72}
        height={54}
      />
    </PlainButton>
  ));
  return (
    <div className={styles.root}>
      <div className={classnames(styles.image, className)}>
        <CarouselButton
          direction={isRtl ? "right" : "left"}
          size="small"
          onClick={() => swiper?.slidePrev()}
          shadow={false}
        />
        <div className={styles.carousel}>
          <SwiperCarousel
            slidesPerView={1}
            itemTypeMessage="images"
            onSwiper={setSwiper}
            carouselItems={slides}
            loop
          />
        </div>
        <CarouselButton
          direction={isRtl ? "left" : "right"}
          size="small"
          onClick={() => swiper?.slideNext()}
          shadow={false}
        />
      </div>
      <div className={styles.thumbnails}>{thumbnails}</div>
    </div>
  );
};
export default ImageCarousel;
